
import DpFormMixin from 'src/core/components/DpForm/DpFormMixin.js'
import DpForm from 'src/core/components/DpForm/DpForm2.vue'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import vTopFilters from 'src/core/filters/vTopFilters'
import { clone } from 'quasar'

import Upload from 'src/components/Upload.vue'
import MarcaModel from 'src/model/veiculo/MarcaModel'
import IcarrosMarcaModel from 'src/model/veiculo/IcarrosMarcaModel'
import WebmotorsMarcaModel from 'src/model/veiculo/WebmotorsMarcaModel'
import UsadosbrMarcaModel from 'src/model/veiculo/UsadosbrMarcaModel'
import OlxMarcaModel from 'src/model/veiculo/OlxMarcaModel'
import MobiautoMarcaModel from 'src/model/veiculo/MobiautoMarcaModel'
export default {
  name: 'MarcaForm',
  mixins: [DpFormMixin, NotificacaoMixin, vTopFilters],
  components: { Upload, DpForm },
  data () {
    return {
      modelPadrao: new MarcaModel(),
      codigos_icarros: [],
      codigos_webmotors: [],
      codigos_usadosbr: [],
      codigos_olx: [],
      codigos_mobiauto: [],
      modelIcarrosMarca: new IcarrosMarcaModel(),
      modelWebmotorsMarca: new WebmotorsMarcaModel(),
      modelUsadosbrMarca: new UsadosbrMarcaModel(),
      modelOlxMarca: new OlxMarcaModel(),
      modelMobiautoMarca: new MobiautoMarcaModel(),
      salvando: false
    }
  },
  meta () {
    return {
      title: 'Marcas',
      meta: [
        { 'name': 'robots', 'content': 'noindex' }
      ]
    }
  },
  methods: {
    setarImagem (e) {
      this.modelPadrao.form.foto = e.arquivo
      this.modelPadrao.form.foto_url_completa = e.arquivo.arquivo_url_completa
    },
    salvarForm (e) {
      this.salvando = true
      let form = clone(this.modelPadrao.form)
      console.log(form)
      this.notificacao('aviso', 'Salvando...', 3000)
      this.modelPadrao.salvar(form).then((res) => {
        console.log(res)
        this.salvando = false
        this.notificacao('salvo', 'Salvo com sucesso', 3000)
        this.$router.push({ name: 'marcas.grid' })
      })
    },
    setarDados () {
      let form = clone(this.modelPadrao.form)
      this.modelPadrao.form = Object.assign({}, form)
    },
    inicio () {
    }
  },
  watch: {
    '$route': {
      handler: function (agora, depois) {
        this.inicio()
      },
      deep: false,
      immediate: false
    }
  },
  mounted () {
    if (this.$route.params.id && this.$route.params.id > 0) {
      this.modelPadrao.getId({ id: this.$route.params.id }).then((response) => {
        this.modelPadrao.form = response.dados
      })
    }
    this.modelIcarrosMarca.getListagem().then((response) => {
      this.codigos_icarros = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
    this.modelWebmotorsMarca.getListagem().then((response) => {
      this.codigos_webmotors = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
    this.modelUsadosbrMarca.getListagem().then((response) => {
      this.codigos_usadosbr = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
    this.modelOlxMarca.getListagem().then((response) => {
      this.codigos_olx = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
    this.modelMobiautoMarca.getListagem().then((response) => {
      this.codigos_mobiauto = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
    this.inicio()
  }
}
